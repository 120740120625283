// @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic');

// // Variables
@import 'variables';

@import '../css/app.css';
// AdminLTE
@import '../../vendor/almasaeed2010/adminlte/dist/css/adminlte.css';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
// @import '../css/bootstrap2-toggle.css';
@import '~bootstrap4-toggle/css/bootstrap4-toggle.min.css';
// admin-lte 
@import '~@fortawesome/fontawesome-free/css/all.css';
// OverlayScrollbars
@import '~overlayscrollbars/styles/overlayscrollbars.css';
// iCheck
@import '~icheck-bootstrap/icheck-bootstrap.css';

/* include CSS for prism toolbar */
@import '~prismjs/plugins/toolbar/prism-toolbar.css';
/* check node_modules/prismjs/themes/ for the available themes */
@import '~prismjs/themes/prism-coy';
// https://www.npmjs.com/package/@kfonts/nanum-gothic
@import '~@kfonts/nanum-gothic';

// $font-path: "../fonts";
@import '~@icon/ionicons/ionicons.css';