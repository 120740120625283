.rc-thead {
    background-color: #e1e1e1;
}

.rc-tr {
    background-color: #ffffff;
}

.rc-tr:nth-child(even) {
    background-color: #f1f1f1;
}

.ck-editor__editable {
    min-height: 500px;
    max-height: 500px;
}

/* ckeditor link bug fix */
.ck-body-wrapper .ck {
    --ck-z-modal: 1261;
}

.ui-dialog~.ck-body-wrapper {
    --ck-z-modal: 1261;
}

.modal pre,
code,
kbd,
samp {
    font-size: 1em;
}

.modal .hr-text {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: black;
    text-align: center;
    height: 1.5em;
    opacity: .5;
    font-size: 0.8rem;
}

.modal .hr-text:before {
    content: '';
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
}

.modal .hr-text:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;
    padding: 0 .5em;
    line-height: 1.5em;
    color: #818078;
    background-color: #fcfcfa;

}

.modal #board_view_comment {
    font-size: 0.85rem;
}

.modal #board_view_comment .right {
    text-align: right;
}

.modal #board_view_comment .direct-chat-text {
    white-space: pre-wrap;
    background-color: #ececec;
    text-align: left;
}

.modal #board_view_comment .right .direct-chat-text {
    white-space: pre-wrap;
    background-color: #f3e9e8;
    text-align: left;
}

.modal .modal-header {
    background-color: #F7F8F9;
}

.modal .modal-footer {
    background-color: #F7F8F9;
}

.modal .modal-footer .column {
    float: left;
    width: 50%;
    padding: 10px;
    line-height: 4px;
}

.modal .modal-footer .left {
    width: 50%;
}

.modal .modal-footer .right {
    width: 50%;
    text-align: right;
}

.table .min-font {
    font-weight: 400;
    font-size: 0.8rem;
    vertical-align: middle;
}

.modal .modal-body {
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
}

.modal .media {
    display: block !important;
    align-items: center !important;
}

.modal img {
    width: 100%;
    max-width: 760px;
}